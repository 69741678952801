import { template as template_5558caf7aa4c4bdea9c62a40fcc71c71 } from "@ember/template-compiler";
const SidebarSectionMessage = template_5558caf7aa4c4bdea9c62a40fcc71c71(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
