import { template as template_2003b25a40f244f696180f3babaa9726 } from "@ember/template-compiler";
const FKControlMenuContainer = template_2003b25a40f244f696180f3babaa9726(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
