import { template as template_702edac5d2904345bb2dee598bb4702e } from "@ember/template-compiler";
const FKLabel = template_702edac5d2904345bb2dee598bb4702e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
